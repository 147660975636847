import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/home.vue')
    },
    {
        path: '/pesquisas-realizadas',
        name: 'search-histories',
        component: () => import('../views/search-histories/search-histories.vue')
    },

    /** EMPRESAS **/
    {
        path: '/unidades',
        name: 'company',
        component: () => import('../views/companies/companies.vue')
    },
    {
        path: '/unidades/cadastrar',
        name: 'company.create',
        component: () => import('../views/companies/company-form.vue')
    },
    {
        path: '/unidades/editar/:id',
        name: 'company.update',
        component: () => import('../views/companies/company-form.vue')
    },

    /** CONFIGURAÇÃO DE BENEFICIOS **/
    {
        path: '/configuracao-beneficio',
        name: 'benefitsConfig',
        component: () => import('../views/benefits_config/benefits-config.vue')
    },
    {
        path: '/configuracao-beneficio/cadastrar',
        name: 'benefitsConfig.create',
        component: () => import('../views/benefits_config/benefits-config-form.vue')
    },
    {
        path: '/configuracao-beneficio/editar/:id',
        name: 'benefitsConfig.update',
        component: () => import('../views/benefits_config/benefits-config-form.vue')
    },

    /** SETORES **/
    {
        path: '/categoria-documentos',
        name: 'documentCategories',
        component: () => import('../views/document_categories/document-categories.vue')
    },

    /** SETORES **/
    {
        path: '/setores',
        name: 'sector',
        component: () => import('../views/sector/sector.vue')
    },
    {
        path: '/setores/cadastrar',
        name: 'sector.create',
        component: () => import('../views/sector/sector-form.vue')
    },
    {
        path: '/setores/editar/:id',
        name: 'sector.update',
        component: () => import('../views/sector/sector-form.vue')
    },

    /** SINDICATOS **/
    {
        path: '/sindicatos',
        name: 'syndicate',
        component: () => import('../views/syndicates/syndicates.vue')
    },
    {
        path: '/sindicatos/cadastrar',
        name: 'syndicate.create',
        component: () => import('../views/syndicates/syndicate-form.vue')
    },
    {
        path: '/sindicatos/editar/:id',
        name: 'syndicate.update',
        component: () => import('../views/syndicates/syndicate-form.vue')
    },
    {
        path: '/sindicatos/:id',
        name: 'syndicate.view',
        component: () => import('../views/syndicates/syndicate-view.vue')
    },

    /** GRUPO SALARIAIS **/
    {
        path: '/grupos-salariais',
        name: 'wageGroups',
        component: () => import('../views/wage_groups/wage-group.vue')
    },
    {
        path: '/grupos-salariais/cadastrar',
        name: 'wageGroups.create',
        component: () => import('../views/wage_groups/wage-group-form.vue')
    },
    {
        path: '/grupos-salariais/editar/:id',
        name: 'wageGroups.update',
        component: () => import('../views/wage_groups/wage-group-form.vue')
    },
    {
        path: '/grupos-salariais/:id',
        name: 'wageGroups.view',
        component: () => import('../views/wage_groups/wage-group-view.vue')
    },

    /** CARGOS **/
    {
        path: '/cargos',
        name: 'position',
        component: () => import('../views/positions/positions.vue')
    },
    {
        path: '/cargos/cadastrar',
        name: 'position.create',
        component: () => import('../views/positions/positions-form.vue')
    },
    {
        path: '/cargos/editar/:id',
        name: 'position.update',
        component: () => import('../views/positions/positions-form.vue')
    },
    {
        path: '/cargos/visualizar/:id',
        name: 'position.view',
        component: () => import('../views/positions/positions.view.vue')
    },

    /** COLABORADORES **/
    {
        path: '/colaboradores',
        name: 'collaborator',
        component: () => import('../views/collaborators/collaborator.vue')
    },
    {
        path: '/colaboradores/cadastrar',
        name: 'collaborator.create',
        component: () => import('../views/collaborators/collaborator-form.vue')
    },
    {
        path: '/colaboradores/editar/:id',
        name: 'collaborator.update',
        component: () => import('../views/collaborators/collaborator-form.vue')
    },
    {
        path: '/colaboradores/visualizar/:id',
        name: 'collaborator.view',
        component: () => import('../views/collaborators/collaborator-view.vue')
    },

    /** CLASSIFICAÇÕES DE CARGOS **/
    {
        path: '/classificacoes-cargos',
        name: 'classification_position',
        component: () => import('../views/classifications/classifications.vue')
    },
    {
        path: '/classificacoes-cargos/classificar/:id',
        name: 'classification_position.classify',
        component: () => import('../views/classifications/classification-form.vue')
    },
    {
        path: '/classificacoes-cargos/criterios',
        name: 'classification_position.criteria',
        component: () => import('../views/classifications/criteria.vue')
    },
    {
        path: '/classificacoes-cargos/criterios',
        name: 'classification_position.criteria',
        component: () => import('../views/classifications/criteria.vue')
    },

    /** PESQUISA SALARIAL **/
    {
        path: '/pesquisa-salarial',
        name: 'salary_survey',
        component: () => import('../views/salary_survey/salary-survey.vue')
    },
    {
        path: '/pesquisa-salarial/resultado/:id',
        name: 'salary_survey.result',
        component: () => import('../views/salary_survey/result.vue')
    },
    {
        path: '/pesquisa-salarial/pesquisa/:id',
        name: 'salary_survey.search',
        component: () => import('../views/salary_survey/search.vue')
    },
    {
        path: '/pesquisa-salarial/pesquisa-beneficios/:id',
        name: 'salary_survey.search-benefits',
        component: () => import('../views/salary_survey/search-benefits.vue')
    },
    {
        path: '/pesquisa-salarial/adicionar-salario/:id',
        name: 'salary_survey.add_salary',
        component: () => import('../views/salary_survey/add-salary.vue')
    },
    {
        path: '/pesquisa-salarial/historicos/:id',
        name: 'salary_survey.histories',
        component: () => import('../views/salary_survey/salary-survey-histories.vue')
    },

    {
        path: '/pesquisa-salarial/dashboard',
        name: 'salary_survey.dashboard',
        component: () => import('../views/salary_survey/dashboard.vue')
    },

    /** DESCRIÇÃO DE CARGOS **/
    {
        path: '/descricao-cargos',
        name: 'position_description',
        component: () => import('../views/position_description/position_descriptions.vue')
    },
    {
        path: '/descricao-cargos/atividades/:id',
        name: 'position.activities',
        component: () => import('../views/position_description/activities.vue')
    },

    /** CURVA SALARIAL **/
    {
        path: '/curva-salarial',
        name: 'salary_curve',
        component: () => import('../views/salary_curve/salary_curve.vue')
    },

    /** TABELA SALARIAL **/
    {
        path: '/tabela-salarial',
        name: 'salary_table',
        component: () => import('../views/salary_table/salary-table.vue')
    },

    /** ADEQUAÇÃO TABELA **/
    {
        path: '/tabela-adequacao',
        name: 'suitability_table',
        component: () => import('../views/suitability_table/suitability_table.vue')
    },
    {
        path: '/tabela-adequacao/historico',
        name: 'suitability_history',
        component: () => import('../views/suitability_table/history.vue')
    },
    {
        path: '/tabela-adequacao/todos-indicadores',
        name: 'suitability_history.full_indicators',
        component: () => import('../views/suitability_table/full_indicators.vue')
    },

    /** CHART **/
    {
        path: '/organograma',
        name: 'chart',
        component: () => import('../views/chart/chart.vue')
    },

    /** POLITICA SALARIAL **/
    {
        path: '/politica-salarial',
        name: 'salary_policy',
        component: () => import('../views/salary_policy/salary_policy.vue')
    },
    {
        path: '/politica-salarial/criterios-classificaccao',
        name: 'classification_criteria',
        component: () => import('../views/salary_policy/classification_criteria.vue')
    },

    /** USUÁRIOS **/
    {
        path: '/usuarios',
        name: 'user',
        component: () => import('../views/users/users.vue')
    },
    {
        path: '/usuarios/cadastrar',
        name: 'user.create',
        component: () => import('../views/users/user-form.vue')
    },
    {
        path: '/usuarios/:id',
        name: 'user.update',
        component: () => import('../views/users/user-form.vue')
    },

    /** CONFIGURAÇÕES **/
    {
        path: '/configuracao',
        name: 'settings',
        component: () => import('../views/settings/settings.vue')
    },

    /** Critérios de decisão - Avaliação de Desempenho **/
    {
        path: '/avaliacao-desempenho/criterios-decisao',
        name: 'decision-criteria',
        component: () => import('../views/evaluation/decision-criteria/decision-criteria.vue')
    },

    /** Avaliações **/
    {
        path: '/avaliacoes',
        name: 'evaluations',
        component: () => import('../views/evaluation/evaluation/evaluations.vue')
    },
    {
        path: '/avaliacoes/cadastrar',
        name: 'evaluations.create',
        component: () => import('../views/evaluation/evaluation/evaluation-form.vue')
    },
    {
        path: '/avaliacoes/editar/:id',
        name: 'evaluations.update',
        component: () => import('../views/evaluation/evaluation/evaluation-form.vue')
    },
    {
        path: '/avaliacoes/configuracao/:id',
        name: 'evaluations.setting',
        component: () => import('../views/evaluation/evaluation/evaluation-setting.vue')
    },
    {
        path: '/avaliacoes/questionario/:id',
        name: 'evaluations.question',
        component: () => import('../views/evaluation/evaluation/evaluation-question.vue')
    },
    {
        path: '/avaliacoes/avaliados/:id',
        name: 'evaluations.evaluated',
        component: () => import('../views/evaluation/evaluation/evaluation-evaluated.vue')
    },
    {
        path: '/avaliacoes/historicos/:id',
        name: 'evaluations.histories',
        component: () => import('../views/evaluation/evaluation/evaluation-histories.vue')
    },
    {
        path: '/avaliacoes/historicos/:id/vizualizar-avaliadores/:history',
        name: 'evaluations.histories-evaluators',
        component: () => import('../views/evaluation/evaluation/history-evaluated.vue')
    },
    {
        path: '/avaliacoes/questionario/:id/cargo/:positionId',
        name: 'evaluations.question.position',
        component: () => import('../views/evaluation/evaluation/position-question.vue')
    },

    /** Ranking **/
    {
        path: '/avaliacao-desempenho/ranking',
        name: 'ranking',
        component: () => import('../views/evaluation/ranking/ranking.vue')
    },

    /** Relatório Geral **/
    {
        path: '/avaliacao-desempenho/relatorio-geral',
        name: 'general',
        component: () => import('../views/evaluation/general-report/general-report.vue')
    },

    /** Relatório Por Colaborador **/
    {
        path: '/avaliacao-desempenho/relatorio-por-colaborador',
        name: 'evaluation.report-collaborator',
        component: () => import('../views/evaluation/collaborator-report/collaborator-report.vue')
    },

    /** Relatório Por Setor **/
    {
        path: '/avaliacao-desempenho/relatorio-por-setor',
        name: 'evaluation.report-sector',
        component: () => import('../views/evaluation/sector-report/sector-report.vue')
    },

    /** PDI **/
    {
        path: '/avaliacao-desempenho/pdi',
        name: 'evaluation.pdi',
        component: () => import('../views/evaluation/pdi/pdi.vue')
    },
    {
        path: '/avaliacao-desempenho/pdi-cadastrar',
        name: 'evaluation.pdi-create',
        component: () => import('../views/evaluation/pdi/pdi-create.vue')
    },
    {
        path: '/avaliacao-desempenho/pdi-editar/:id',
        name: 'evaluation.pdi-update',
        component: () => import('../views/evaluation/pdi/pdi-update.vue')
    },


    /** Avaliações Externas **/
    {
        path: '/avaliacao/avaliado/login/:uuid',
        name: 'external-evaluations.evaluated-login',
        component: () => import('../views/external_evaluation/evaluated_login.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliado/form/:uuid',
        name: 'external-evaluations.evaluated-form',
        component: () => import('../views/external_evaluation/evaluated_form.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliados/:uuid',
        name: 'external-evaluations.evaluated-list',
        component: () => import('../views/external_evaluation/evaluator_list.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/sucesso',
        name: 'external-evaluations.evaluated-success',
        component: () => import('../views/external_evaluation/evaluated_success.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliador/login/:uuid',
        name: 'external-evaluations.evaluators-login',
        component: () => import('../views/external_evaluation/evaluators_login.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliador/form/:uuid/:collaboratorId',
        name: 'external-evaluations.evaluator-form',
        component: () => import('../views/external_evaluation/evaluator_form.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliador/form-consensual/:uuid/:collaboratorId',
        name: 'external-evaluations.evaluator-form-consensual',
        component: () => import('../views/external_evaluation/evaluator_consensual_form.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao/avaliador/sucesso/:uuid',
        name: 'external-evaluations.evaluator-success',
        component: () => import('../views/external_evaluation/evaluator_success.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao-consensual/avaliador/form/:uuid/:collaboratorId',
        name: 'external-evaluations.consensual-evaluator-form',
        component: () => import('../views/external_evaluation/evaluator_consensual_form.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/avaliacao-consensual/avaliador/sucesso/:uuid',
        name: 'external-evaluations.consensual-evaluator-success',
        component: () => import('../views/external_evaluation/evaluator_consensual_success.vue'),
        meta: { layout: 'auth' }
    },

    /** AUTH **/
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => store.dispatch('user/logout'),
        meta: { layout: 'auth' }
    },
    {
        path: '/recuperacao-senha/:token',
        name: 'recovery',
        component: () => import('../views/auth/recovery.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/redefinir-senha/:token',
        name: 'reset',
        component: () => import('../views/auth/newPassword.vue'),
        meta: { layout: 'auth' }
    },

    /** ANÁLISE DESCRIÇÕES **/
    {
        path: '/analise-descricoes',
        name: 'descriptions_analysis',
        component: () => import('../views/description-analysis/description-analysis.vue')
    },
    {
        path: '/analise-descricoes/:id',
        name: 'descriptions_analysis.form',
        component: () => import('../views/description-analysis/description-analysis-form.vue')
    },


    /** PORTAL */
    {
        path: '/portal',
        name: 'portal.home',
        component: () => import('../views/portal/home/home.vue'),
        meta: { layout: 'portal' }
    },
    {
        path: '/portal/descricao-cargo',
        name: 'portal.position-description',
        component: () => import('../views/portal/position-description/position-description.vue'),
        meta: { layout: 'portal' }
    },
    {
        path: '/portal/aprovacao-descricoes',
        name: 'portal.approval-description',
        component: () => import('../views/description-analysis/description-analysis.vue'),
        meta: { layout: 'portal' }
    },
    {
        path: '/portal/aprovacao-descricoes/:id',
        name: 'portal.approval-description.form',
        component: () => import('../views/description-analysis/description-analysis-form.vue'),
        meta: { layout: 'portal' }
    }
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    store.dispatch('user/loadUserData');

    if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
        store.commit('setLayout', 'auth');

    } else if (to.meta && to.meta.layout && to.meta.layout === 'portal') {
        if (!store.getters['user/isAuth']) {
            window.location.href = '/login';
        }
        
        store.commit('setLayout', 'portal');
    } else {
        if (!store.getters['user/isAuth']) {
            window.location.href = '/login';
        } else {
            if (store.state.user.user.permissions.includes('role:collaborator')) {
                window.location.href = '/portal';
            }
        }
        
        store.commit('setLayout', 'app');
    }

    if (store.getters['user/isAuth']) {
        const formAccess = { url_path: to.fullPath };
        
        let column_log = 'user_id';
        if (store.state.user.user.permissions.includes('role:collaborator')) {
            column_log = 'collaborator_id';
        }

        formAccess[column_log] = store.state.user.user.id;
        store.dispatch('user/accessLog', formAccess);
    }

    next(true);
});

export default router;
