export default {
    namespaced: true,
    state: () => ({
        route: {
            page: 1,
            totalPage: 15,
            order: 'orderBy[name]=ASC'
        },
        formFilter: {
            show: false,
            search: null,
            activeFilter: true,
            company_id: null,
            companies: [],
            position_id: null,
            cbo_id: null,
            employment_relationship: null,
            sector_id: null,
            is_null: false
        }
    }),

    mutations: {
        setRouteDefault(state) {
            state.route = {
                page: 1,
                totalPage: 15,
                order: 'orderBy[name]=ASC'
            };

            state.formFilter = {
                show: false,
                search: null,
                activeFilter: true,
                company_id: null,
                position_id: null,
                cbo_id: null,
                employment_relationship: null,
                sector_id: null,
                is_null: false
            };
        }
    },

    getters: {
        countDescriptionPositions(state) {
            const form = { ...state.formFilter };
            delete form.search;
            delete form.show;
            delete form.activeFilter;

            return Object.keys(form).filter(key => form[key] !== null).length;
        },

        getFilter(state) {
            let endpoint = ``;
            if (state.formFilter.company_id) {
                endpoint += `&whereWith[position][company_id]=${state.formFilter.company_id}`;
            }

            if (state.formFilter.position_id) {
                endpoint += `&where[position_id]=${state.formFilter.position_id}`;
            }

            if (state.formFilter.search) {
                endpoint += `&whereLike[name]=${state.formFilter.search}`;
            }

            if (state.formFilter.cbo_id) {
                endpoint += `&where[cbo_id]=${state.formFilter.cbo_id}`;
            }

            if (state.formFilter.sector_id) {
                endpoint += `&whereWith[position][sector_id]=${state.formFilter.sector_id}`;
            }

            if (state.formFilter.employment_relationship) {
                endpoint += `&where[employment_relationship]=${state.formFilter.employment_relationship}`;
            }

            if (state.formFilter.is_null) {
                endpoint += `&where[cpf]=null`;
            }

            return endpoint;
        }
    }
};
