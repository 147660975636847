import { createAcl, defineAclRules } from 'vue-simple-acl';
import router from './router';

const user = JSON.parse(localStorage.getItem('_user'));

const rules = () => defineAclRules(async (setRule) => {
    setRule('dashboard-view', (user) => user?.permissions && user?.permissions.includes('dashboard-view'));
    
    setRule('position-description-view', (user) => user?.permissions && user?.permissions.includes('position-description-view'));
    setRule('position-description-edit', (user) => user?.permissions && user?.permissions.includes('position-description-edit'));
    setRule('position-description-print', (user) => user?.permissions && user?.permissions.includes('position-description-print'));
    setRule('position-description-clear', (user) => user?.permissions && user?.permissions.includes('position-description-clear'));

    setRule('classification-view', (user) => user?.permissions && user?.permissions.includes('classification-view'));
    setRule('classification-to-rank', (user) => user?.permissions && user?.permissions.includes('classification-to-rank'));
    setRule('classification-criteria', (user) => user?.permissions && user?.permissions.includes('classification-criteria'));

    setRule('salary-survey-view', (user) => user?.permissions && user?.permissions.includes('salary-survey-view'));
    setRule('salary-survey-search', (user) => user?.permissions && user?.permissions.includes('salary-survey-search'));
    setRule('salary-survey-add-search', (user) => user?.permissions && user?.permissions.includes('salary-survey-add-search'));
    setRule('salary-survey-remove-companies', (user) => user?.permissions && user?.permissions.includes('salary-survey-remove-companies'));
    setRule('salary-survey-clear', (user) => user?.permissions && user?.permissions.includes('salary-survey-clear'));
    
    setRule('salary-curve-view', (user) => user?.permissions && user?.permissions.includes('salary-curve-view'));
    setRule('salary-curve-save', (user) => user?.permissions && user?.permissions.includes('salary-curve-save'));
    setRule('salary-table-view', (user) => user?.permissions && user?.permissions.includes('salary-table-view'));

    setRule('suitability-table-view', (user) => user?.permissions && user?.permissions.includes('suitability-table-view'));
    setRule('suitability-table-save', (user) => user?.permissions && user?.permissions.includes('suitability-table-save'));
    setRule('suitability-table-percent', (user) => user?.permissions && user?.permissions.includes('suitability-table-percent'));
    setRule('suitability-table-consolidated', (user) => user?.permissions && user?.permissions.includes('suitability-table-consolidated'));
    setRule('suitability-table-history', (user) => user?.permissions && user?.permissions.includes('suitability-table-history'));
    setRule('suitability-table-rollback', (user) => user?.permissions && user?.permissions.includes('suitability-table-rollback'));

    setRule('chart-view', (user) => user?.permissions && user?.permissions.includes('chart-view'));
    setRule('chart-download', (user) => user?.permissions && user?.permissions.includes('chart-download'));
    setRule('chart-order', (user) => user?.permissions && user?.permissions.includes('chart-order'));

    setRule('salary-policy-view', (user) => user?.permissions && user?.permissions.includes('salary-policy-view'));
    setRule('salary-policy-print', (user) => user?.permissions && user?.permissions.includes('salary-policy-print'));
    setRule('salary-policy-update', (user) => user?.permissions && user?.permissions.includes('salary-policy-update'));

    setRule('sector-view', (user) => user?.permissions && user?.permissions.includes('sector-view'));
    setRule('sector-create', (user) => user?.permissions && user?.permissions.includes('sector-create'));
    setRule('sector-edit', (user) => user?.permissions && user?.permissions.includes('sector-edit'));
    setRule('sector-delete', (user) => user?.permissions && user?.permissions.includes('sector-delete'));

    setRule('position-view', (user) => user?.permissions && user?.permissions.includes('position-view'));
    setRule('position-create', (user) => user?.permissions && user?.permissions.includes('position-create'));
    setRule('position-edit', (user) => user?.permissions && user?.permissions.includes('position-edit'));
    setRule('position-delete', (user) => user?.permissions && user?.permissions.includes('position-delete'));

    setRule('user-view', (user) => user?.permissions && user?.permissions.includes('user-view'));
    setRule('user-create', (user) => user?.permissions && user?.permissions.includes('user-create'));
    setRule('user-update', (user) => user?.permissions && user?.permissions.includes('user-update'));
    setRule('user-delete', (user) => user?.permissions && user?.permissions.includes('user-delete'));

    setRule('collaborators-view', (user) => user?.permissions && user?.permissions.includes('collaborators-view'));
    setRule('collaborators-create', (user) => user?.permissions && user?.permissions.includes('collaborators-create'));
    setRule('collaborators-edit', (user) => user?.permissions && user?.permissions.includes('collaborators-edit'));
    setRule('collaborators-delete', (user) => user?.permissions && user?.permissions.includes('collaborators-delete'));

    setRule('syndicate-view', (user) => user?.permissions && user?.permissions.includes('syndicate-view'));
    setRule('syndicate-create', (user) => user?.permissions && user?.permissions.includes('syndicate-create'));
    setRule('syndicate-edit', (user) => user?.permissions && user?.permissions.includes('syndicate-edit'));
    setRule('syndicate-delete', (user) => user?.permissions && user?.permissions.includes('syndicate-delete'));

    setRule('wage-groups-view', (user) => user?.permissions && user?.permissions.includes('wage-groups-view'));
    setRule('wage-groups-create', (user) => user?.permissions && user?.permissions.includes('wage-groups-create'));
    setRule('wage-groups-edit', (user) => user?.permissions && user?.permissions.includes('wage-groups-edit'));
    setRule('wage-groups-delete', (user) => user?.permissions && user?.permissions.includes('wage-groups-delete'));

    setRule('dashboard-evaluation-view', (user) => user?.permissions && user?.permissions.includes('dashboard-evaluation-view'));
    setRule('ranking-evaluation-view', (user) => user?.permissions && user?.permissions.includes('ranking-evaluation-view'));
    setRule('dashboard-sector-evaluation-view', (user) => user?.permissions && user?.permissions.includes('dashboard-sector-evaluation-view'));
    setRule('dashboard-collaborator-evaluation-view', (user) => user?.permissions && user?.permissions.includes('dashboard-collaborator-evaluation-view'));
    
    setRule('evaluation-view', (user) => user?.permissions && user?.permissions.includes('evaluation-view'));
    setRule('evaluation-delete', (user) => user?.permissions && user?.permissions.includes('evaluation-delete'));
    setRule('evaluation-create', (user) => user?.permissions && user?.permissions.includes('evaluation-create'));
    setRule('evaluation-start', (user) => user?.permissions && user?.permissions.includes('evaluation-start'));
    setRule('evaluation-update', (user) => user?.permissions && user?.permissions.includes('evaluation-update'));
    setRule('evaluation-closed', (user) => user?.permissions && user?.permissions.includes('evaluation-closed'));
    
    setRule('decision-criteria-update', (user) => user?.permissions && user?.permissions.includes('decision-criteria-update'));
    setRule('decision-criteria-view', (user) => user?.permissions && user?.permissions.includes('decision-criteria-view'));
    
    setRule('pdi-create', (user) => user?.permissions && user?.permissions.includes('pdi-create'));
    setRule('pdi-delete', (user) => user?.permissions && user?.permissions.includes('pdi-delete'));
    setRule('pdi-update', (user) => user?.permissions && user?.permissions.includes('pdi-update'));
    setRule('pdi-view', (user) => user?.permissions && user?.permissions.includes('pdi-view'));
    
    setRule('company-create', (user) => user?.permissions && user?.permissions.includes('company-create'));
    setRule('company-delete', (user) => user?.permissions && user?.permissions.includes('company-delete'));
    setRule('company-update', (user) => user?.permissions && user?.permissions.includes('company-update'));
    setRule('company-view', (user) => user?.permissions && user?.permissions.includes('company-view'));
    
    setRule('benefits-config-create', (user) => user?.permissions && user?.permissions.includes('benefits-config-create'));
    setRule('benefits-config-delete', (user) => user?.permissions && user?.permissions.includes('benefits-config-delete'));
    setRule('benefits-config-update', (user) => user?.permissions && user?.permissions.includes('benefits-config-update'));
    setRule('benefits-config-view', (user) => user?.permissions && user?.permissions.includes('benefits-config-view'));
    
    setRule('analyze-description-view', (user) => user?.permissions && user?.permissions.includes('analyze-description-view'));
    setRule('analyze-description-save', (user) => user?.permissions && user?.permissions.includes('analyze-description-save'));
    setRule('analyze-description-compile', (user) => user?.permissions && user?.permissions.includes('analyze-description-compile'));
    setRule('analyze-description-comments', (user) => user?.permissions && user?.permissions.includes('analyze-description-comments'));
    setRule('analyze-description-flow', (user) => user?.permissions && user?.permissions.includes('analyze-description-flow'));
    setRule('analyze-description-redirect', (user) => user?.permissions && user?.permissions.includes('analyze-description-redirect'));
    setRule('analyze-description-view-portal', (user) => user?.permissions && user?.permissions.includes('analyze-description-view-portal'));
    setRule('role:collaborator', (user) => user?.permissions && user?.permissions.includes('role:collaborator'));
});

const simpleAcl = createAcl({ user, rules, router });
export default simpleAcl;
