<script setup>
    import { watch, computed, onBeforeMount } from "vue";
    import { useStore } from "vuex";

    const store = useStore();
    const url_api = process.env.VUE_APP_HOST_API + 'transactional-nps-search';
    const url_avatar = process.env.VUE_APP_TYPEBOT_HOST_AVATAR;

    const isVisibleTransactionalNpmSearch = computed(() => store.getters['transactionalNps/isVisible']);
    const isVisibleRelacionalNpmSearch = computed(() => store.getters['relationalNps/canDisplayTheSearch']);
    const canDisplayTheSearch = computed(() => store.getters['transactionalNps/canDisplayTheSearch']);

    function removeTypeBot()
    {
        const typebotElement = document.getElementById('transactional-nps-search');
        if(typebotElement) typebotElement.parentNode.removeChild(typebotElement);
    }

    onBeforeMount(() => {
        store.dispatch('transactionalNps/getLastSearchPerformed');
    });

    watch([isVisibleRelacionalNpmSearch, isVisibleTransactionalNpmSearch, canDisplayTheSearch], (state) => {

        const isVisibleRelacionalNpmSearch = state[0];
        const canDisplayTheSearch = state[1] && state[2];

        if(isVisibleRelacionalNpmSearch) {            
            store.commit('transactionalNps/hide');            
        }                
        else if(canDisplayTheSearch) {
            Typebot.initBubble({
                id: 'transactional-nps-search',
                typebot: "nps-2-0-transacional-x2y9mje",
                apiHost: "https://typechat.deppes.com",
                prefilledVariables: {
                    url_api: url_api,
                    token: store.state.user.token,                
                    client_name: store.state.user.user.name,
                },        
                previewMessage: {
                    message: "Contribua com seu feedback!",
                    avatarUrl: url_avatar, 
                },        
                    theme: {
                    button: { backgroundColor: "#0042DA" },
                    chatWindow: { backgroundColor: "#fff" },
                },
                onEnd() {
                    setInterval(() => Typebot.close(), 2000);
                    setInterval(() => removeTypeBot(), 4000);
                }
            });
            Typebot.showPreviewMessage();
        }
        else removeTypeBot();
    });
</script>

<template></template>
