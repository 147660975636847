<template>
    <!--  BEGIN SIDEBAR  -->
    <div class='sidebar-wrapper sidebar-theme'>
        <nav ref='menu' id='sidebar'>
            <div class='shadow-bottom'></div>

            <perfect-scrollbar
                class='list-unstyled menu-categories' tag='ul'
                :options='{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }'>

                <li class='menu'>
                    <router-link :to='{name: "home"}' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-chart-pie' />
                            <span>Dashboard</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu' v-can:salary-survey-view>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#search'
                       aria-controls='components' aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-search' />
                            <span>Pesquisa</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='22'
                                height='22'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='search' class='collapse submenu list-unstyled' data-bs-parent='#search'>
                        <li v-can:salary-survey-view>
                            <router-link :to='{name: "salary_survey"}' @click='toggleMobileMenu'>
                                Pesquisa Salarial
                            </router-link>
                        </li>
                        <li v-can:salary-survey-view>
                            <router-link :to='{name: "salary_survey.dashboard"}' @click='toggleMobileMenu'>
                                Dashboard
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu' v-can.any="['suitability-table-view', 'salary-policy-view', 'salary-policy-view', 
                'salary-table-view', 'salary-curve-view', 'classification-view', 'position-description-view']">
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#positions'
                       aria-controls='components' aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-briefcase' />
                            <span>Cargos e Salár.</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='22'
                                height='22'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='positions' class='collapse submenu list-unstyled' data-bs-parent='#positions'>
                        <li v-can:position-description-view>
                            <router-link :to='{name: "position_description"}' @click='toggleMobileMenu'>
                                Descrições Cargos
                            </router-link>
                        </li>
                        <li v-can:analyze-description-view>
                            <router-link :to='{name: "descriptions_analysis"}' @click='toggleMobileMenu'>
                                Analisar Descrições
                            </router-link>
                        </li>
                        <li v-can:classification-view>
                            <router-link :to='{name: "classification_position"}' @click='toggleMobileMenu'>
                                Classificações
                            </router-link>
                        </li>
                        <li v-can:salary-curve-view>
                            <router-link :to='{name: "salary_curve"}' @click='toggleMobileMenu'>
                                Curva Salarial
                            </router-link>
                        </li>
                        <li v-can:salary-table-view>
                            <router-link :to='{name: "salary_table"}' @click='toggleMobileMenu'>
                                Tabela Salarial
                            </router-link>
                        </li>
                        <li v-can:suitability-table-view>
                            <router-link :to='{name: "suitability_table"}' @click='toggleMobileMenu'>
                                Gestão de Carreiras
                            </router-link>
                        </li>
                        <li v-can:chart-view>
                            <router-link :to='{name: "chart"}' @click='toggleMobileMenu'>
                                Organograma
                            </router-link>
                        </li>
                        <li v-can:salary-policy-view>
                            <router-link :to='{name: "salary_policy"}' @click='toggleMobileMenu'>
                                Política Salarial
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu' v-can.any="['dashboard-evaluation-view', 'ranking-evaluation-view', 
                    'dashboard-sector-evaluation-view', 'dashboard-collaborator-evaluation-view', 'evaluation-view',
                    'decision-criteria-view', 'pdi-view']">
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#evaluation'
                       aria-controls='components' aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-chart-bar' />
                            <span>Avaliação Des.</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='evaluation' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li v-can:evaluation-view>
                            <router-link :to='{name: "evaluations", query: {history: true}}' @click='toggleMobileMenu'>
                                Avaliações
                            </router-link>
                        </li>
                        <li v-can:ranking-evaluation-view>
                            <router-link :to='{name: "ranking"}' @click='toggleMobileMenu'>
                                Ranking
                            </router-link>
                        </li>
                        <li v-can:dashboard-evaluation-view>
                            <router-link :to='{name: "general"}' @click='toggleMobileMenu'>
                                Relatório Geral
                            </router-link>
                        </li>
                        <li v-can:dashboard-collaborator-evaluation-view>
                            <router-link :to='{name: "evaluation.report-collaborator"}' @click='toggleMobileMenu'>
                                Rel. P/ Colaborador
                            </router-link>
                        </li>
                        <li v-can:dashboard-sector-evaluation-view>
                            <router-link :to='{name: "evaluation.report-sector"}' @click='toggleMobileMenu'>
                                Relatório. P/ Setor
                            </router-link>
                        </li>
                        <li v-can:pdi-view>
                            <router-link :to='{name: "evaluation.pdi"}' @click='toggleMobileMenu'>
                                PDI
                            </router-link>
                        </li>
                        <li v-can:decision-criteria-view>
                            <router-link :to='{name: "decision-criteria"}' @click='toggleMobileMenu'>
                                Critérios de Decisão
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu' v-can.any="['sector-view', 'position-view', 'collaborators-view', 'user-view', 'benefits-config-view']">
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#components'
                       aria-controls='components' aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-clipboard-list' />
                            <span>Cadastros</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='components' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li v-can:collaborators-view>
                            <router-link :to='{name: "collaborator"}' @click='toggleMobileMenu'>
                                Colaboradores
                            </router-link>
                        </li>
                        <li v-can:position-view>
                            <router-link :to='{name: "position"}' @click='toggleMobileMenu'>
                                Cargos
                            </router-link>
                        </li>
                        <li v-can:sector-view>
                            <router-link :to='{name: "sector"}' @click='toggleMobileMenu'>
                                Setores
                            </router-link>
                        </li>
                        <li v-can:syndicate-view>
                            <router-link :to='{name: "syndicate"}' @click='toggleMobileMenu'>
                                Sindicatos
                            </router-link>
                        </li>
                        <li v-can:wage-groups-view>
                            <router-link :to='{name: "wageGroups"}' @click='toggleMobileMenu'>
                                Grupos Salariais
                            </router-link>
                        </li>
                        <li v-can:benefits-config-view>
                            <router-link :to='{name: "benefitsConfig"}' @click='toggleMobileMenu'>
                                Benefícios
                            </router-link>
                        </li>
                        <li v-can:company-view>
                            <router-link :to='{name: "company"}' @click='toggleMobileMenu'>
                                Unidades
                            </router-link>
                        </li>
                        <li v-can:user-view>
                            <router-link :to='{name: "user"}' @click='toggleMobileMenu'>
                                Usuários
                            </router-link>
                        </li>
                        <li v-can:benefits-config-view>
                            <router-link :to='{name: "documentCategories"}' @click='toggleMobileMenu'>
                                Categoria de <br> Documentos
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <router-link :to='{name: "settings"}' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-gear' />
                            <span>Configuração</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu mt-3'
                    v-can:salary-survey-view v-if='store.state.user.sidebarInfo.total_searches !== null'>
                    <router-link :to='{name: "search-histories"}' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class='w-100'>
                            <div>
                                <font-awesome-icon icon='fa-brands fa-searchengin' />
                                <span>Pesquisas ({{ formatPercent(percentUsed) }})</span>
                            </div>
                            <div class='progress mt-2 mb-1' aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'>
                                <div :class='["progress-bar", percentUsed < 75 ? "bg-success" : "bg-warning"]'
                                     :style='{width: percentUsedStyle(percentUsed)}'></div>
                            </div>
                            <div class='text-muted'>
                                {{ store.state.user.sidebarInfo.total_used_searches }} de
                                {{ store.state.user.sidebarInfo.total_searches }}
                                {{ store.state.user.sidebarInfo.total_searches <= 1 ? 'usado' : 'usados' }}
                            </div>
                        </div>
                    </router-link>
                </li>

                <li class='menu' v-if='(positions !== null && positions.total > 0)'>
                    <div class="dropdown-toggle">
                        <div class='w-100'>
                            <div>
                                <font-awesome-icon icon='fa-solid fa-users' />
                                <span>
                                    Cargos ({{ formatPercent(positions.percentage) }})
                                </span>
                            </div>
                            <div class='progress mt-2 mb-1' aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'>
                                <div :class='["progress-bar", progressBarState(positions.percentage)]'
                                     :style='{width: percentUsedStyle(positions.percentage)}'></div>
                            </div>
                            <div class='text-muted'>
                                {{ positions.use }} de
                                {{ positions.total }}
                                {{ positions.total <= 1 ? 'contratado' : 'contratados' }}
                            </div>
                        </div>
                    </div>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { formatPercent } from '@/helpers';

    const store = useStore();

    onMounted(async () => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }

        store.dispatch('user/getSidebarInfo');
    });

    const positions = computed(() => store.state.user.sidebarInfo.positions);
    
    const percentUsed = computed(() => {
        return (store.state.user.sidebarInfo.total_used_searches / store.state.user.sidebarInfo.total_searches) * 100;
    });
    
    const percentUsedStyle = (value) => {
        return `${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 2 }).format(value)}%`;
    } 
    
    const progressBarState = (state) => {
        if(state < 75) return "bg-success";
        else if (state > 100) return "bg-danger";
        else return "bg-warning";
    }

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>

<style scoped>
    .progress {
        background-color: #a4a5a7;
        border-radius: 8px;
        height: 7px;
    }
</style>
